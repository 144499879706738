import React from "react"
import style from "../../svg.module.scss"

const width = 25;
const height = 25;

const ChairSVG = (props) => (
  <svg viewBox="0 0 512 512"
       height={height} width={width}
       className={style.svg_parent + " " + props.className}
       xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink"
       fillRule="evenodd" clipRule="evenodd">

      <path d="m400 272h-24v-128h16a8 8 0 0 0 8-8v-120a8 8 0 0 0 -8-8h-272a8 8 0 0 0 -8 8v120a8 8 0 0 0 8 8h16v128h-24a8 8 0 0 0 -8 8v40a8 8 0 0 0 8 8v168a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8v-48h192v48a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8v-168a8 8 0 0 0 8-8v-40a8 8 0 0 0 -8-8zm-272-144v-104h256v104zm232 16v128h-16v-128zm-32 0v128h-144v-128zm-160 0v128h-16v-128zm-24 344h-16v-160h16zm208-56h-192v-8h192zm0-24h-192v-80h192zm32 80h-16v-160h16zm8-176h-272v-24h272z"/>
      <circle cx="144" cy="40" r="8"/>
      <circle cx="144" cy="112" r="8"/>
      <circle cx="368" cy="40" r="8"/>
      <circle cx="368" cy="112" r="8"/>

  </svg>
)

export default ChairSVG;
