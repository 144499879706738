import React from "react";
import StandingSVG from "../../_ARCHIVE_/assets/svgs/meta/position/StandingSVG"
import SittingSVG from "../../_ARCHIVE_/assets/svgs/meta/position/SittingSVG"
import LyingSVG from "../../_ARCHIVE_/assets/svgs/meta/position/LyingSVG"

const PositionEnums = {
  standing : {
    label : "Standing",
    svg : <StandingSVG/>
  },
  sitting : {
    label : "Sitting",
    svg : <SittingSVG/>
  },
  lying : {
    label : "Sitting",
    svg : <LyingSVG/>
  },
  any : {
    label : "Any",
    svg : <LyingSVG/>
  }
};

export default PositionEnums;
