import React from "react"

import style from "./DefaultToggle.module.scss";
import Box from "../../../layout/boxes/Box";

const DefaultToggle = ({ className, checked, onToggle, label }) => {

    let cls = (className) ? " " + className : "";
    cls += checked ? " " + style.checked : "";
    return (
        <aside className={style.parent + " " + cls} onClick={onToggle}>
            <h4 className={style.toggle_label}>{label}</h4>
            <Box className={style.track}>
                <div className={style.mark}/>
            </Box>
        </aside>
    )
}

export default DefaultToggle
