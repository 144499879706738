import React, {Component} from "react";
import MiscUtil from "../../../bosom/utils/MiscUtil";
import ActivityModel from "../model/ActivityModel";
import FormatUtil from "../../../bosom/utils/FormatUtil";
import SvgHold from "../../../assets/svg/core/SvgHold";
import style from "./../view/player/text-overlay/PlayerTextOverlay.module.scss";
import UpNext from "../view/up-next/UpNext";
import {navigate} from "../../../../.cache/gatsby-browser-entry";
import SvgPlay from "../../../assets/svg/core/SvgPlay";

class PlayerTextController extends Component {
    constructor(props){
        super(props);
        this.model = ActivityModel.instance;
        this.onStateChanging = this.onStateChanging.bind(this);
        this.onHoldingChange = this.onHoldingChange.bind(this);
        this.onPlayStateChange = this.onPlayStateChange.bind(this);

        this.model.configure(this, ["holding", "isPlaying", "isDone", "autoplay", "exerciseSvgData"]);

        this.reset = this.reset.bind(this);
        this.onHoldingTick = this.onHoldingTick.bind(this);
        this.onDoneTick = this.onDoneTick.bind(this);
        this.onDoAutoplayTick = this.onDoAutoplayTick.bind(this);
        this.setOverlayValues = this.setOverlayValues.bind(this);
    }

    reset(){
        clearTimeout(this.timerUID);
        this.setOverlayValues(undefined,undefined);
        this.timeLeft = 0;
    }

    onStateChanging(propName, propValue) {
        switch(propName) {
            case "holding":
            case "isDone":
            case "autoplay":
            case "exerciseSvgData":
            case "isPlaying": this.onPlayStateChange(); break;
        }
    }

    onPlayStateChange(){
        const isDone = this.model.isDone;
        const isPlaying = this.model.isPlaying;
        const holding = this.model.holding;
        const exerciseSvgData = this.model.exerciseSvgData;
        const didAutoplay = this.model.didAutoplay;

        if(isDone){
            this.timeLeft = 5000;
            this.onDoneTick();
        }
        else if(holding){
            this.onHoldingChange(holding);
        }
        else if(isPlaying){
            this.reset();
        }
        else if(didAutoplay && exerciseSvgData){
            this.timeLeft = 5000;
            this.onDoAutoplayTick();
        }
        else{
            this.reset();
        }
    }

    onIsPlayingChange(isPlaying){
        if(isPlaying === false){
            this.reset();
        }
        else{
            this.onHoldingChange(this.model.holding);
        }
    }

    onHoldingChange(isHolding){
        if(isHolding){
            this.timeLeft = this.model.timeout;
            this.onHoldingTick();
        }
    }

    setOverlayValues(elm, svg){
        this.model.playerOverlayElm = elm;
        this.model.playerOverlaySvg = svg;
    }

    onHoldingTick(){
        if(this.mounted && this.timeLeft > 499){
            const timeStr = FormatUtil.getTimeStrFromMilliseconds(this.timeLeft);
            this.timerUID = MiscUtil.callLater(this.onHoldingTick, 1000, this.timerUID);
            this.timeLeft = this.timeLeft - 1000;
            this.setOverlayValues("Hold here for: " + timeStr, <SvgHold className={style.hold_svg}/>);
        }
        else{
            this.reset();
        }
    }

    onDoAutoplayTick(){
        if(this.mounted && this.timeLeft > 999){
            const timeStr = FormatUtil.getTimeStrFromMilliseconds(this.timeLeft);
            this.timerUID = MiscUtil.callLater(this.onDoAutoplayTick, 1000, this.timerUID);
            this.timeLeft = this.timeLeft - 1000;
            this.setOverlayValues("Autoplay starting in: " + timeStr, <SvgPlay className={style.hold_svg}/>);
        }
        else{
            this.reset();
            this.model.didAutoplay = false;
            this.model.isPlaying = true;
        }
    }

    onDoneTick(){
        if(this.mounted){
            if(this.model.autoplay){
                const timeStr = FormatUtil.getTimeStrFromMilliseconds(this.timeLeft);
                this.setOverlayValues(<UpNext headingText={"Starting next in: " + timeStr}/>);
                if(this.timeLeft > 999){
                    this.timerUID = MiscUtil.callLater(this.onDoneTick, 1000, this.timerUID);
                    this.timeLeft = this.timeLeft - 1000;
                }
                else{
                    this.doAutoplay();
                }
            }
            else{
                this.setOverlayValues(<UpNext/>);
            }
        }
    }

    doAutoplay(){
        if(this.model.nextVO && this.model.autoplay){
            this.model.didAutoplay = true;
            navigate(this.model.nextVO.path);
        }
    }

    render() {return (<></>)}
}

export default PlayerTextController;
