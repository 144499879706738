import React from "react";
import LogoSVG from "../../_ARCHIVE_/assets/svgs/core/LogoSVG"
import ChairSVG from "../../_ARCHIVE_/assets/svgs/meta/equipment/ChairSVG"
import ArrowRightSVG from "../../_ARCHIVE_/assets/svgs/core/ArrowRightSVG"
import TableSVG from "../../_ARCHIVE_/assets/svgs/meta/equipment/TableSVG"

const EquipmentEnums = {
  chair : {
    label : "Chair",
    svg : <ChairSVG/>
  },
  table : {
    label : "Table",
    svg : <TableSVG/>
  },
  ball : {
    label : "Ball",
    svg : <LogoSVG/>
  },
  ping_pong_ball : {
    label : "Ping Pong Ball",
    svg : <ArrowRightSVG/>
  }
};

export default EquipmentEnums;
