import LogoSVG from "../../_ARCHIVE_/assets/svgs/core/LogoSVG";
import React from "react";

const CategoryEnum = {
  //illnesses / purpose: should only be assigned to Tracks
  als : {
    label : "ALS",
    description : "Category of items that relate to ALS",
    svg : <LogoSVG/>
  },
  ms : {
    label : "MS",
    description : "Category of items that relate to MS",
    svg : <LogoSVG/>
  },

  //therapy: Should only be assigned to sessions
  physical : {
    label : "PHYSICAL",
    description : "Category of items that relate to PHYSICAL",
    svg : <LogoSVG/>
  },
  occupational : {
    label : "Occupational Therapy",
    tag_name : "Occupational-Therapy",
    description : "Category of items that relate to OCCUPATIONAL",
    svg : <LogoSVG/>
  },
  respiratory : {
    label : "RESPIRATORY",
    description : "Category of items that relate to RESPIRATORY",
    svg : <LogoSVG/>
  },
  speech : {
    label : "SPEECH",
    description : "SPEECHCategory of items that relate to SPEECH",
    svg : <LogoSVG/>
  },

  //target of exercises: Should only be assigned to exercises
  hand : {
    label : "HAND",
    description : "Category of items that relate to HAND exercises",
    svg : <LogoSVG/>
  },
};

export default CategoryEnum;
