import React from "react"
import style from "../svg.module.scss"

const width = 20;
const height = 20;

const LogoSVG = (props) => (
  <svg viewBox="0 0 24 24"
       height={height} width={width}
       className={style.svg_parent + " " + props.className}
       xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink"
       fillRule="evenodd" clipRule="evenodd">

    <path className={style.logo} d="M10 18.91c-4.808-.688-8.5-4.635-8.5-9.393 0-5.243 4.481-9.5 10-9.5 5.52 0 10 4.257 10 9.5 0 4.758-3.691 8.705-8.5 9.393v5.093h-3v-5.093zm3-1.012c4.252-.676 7.5-4.177 7.5-8.381 0-4.687-4.037-8.5-9-8.5-4.962 0-9 3.813-9 8.5 0 4.204 3.249 7.705 7.5 8.381v-3.887l-4.716-3.44.589-.808 4.127 3.01v-2.964l-2.907-2.14.593-.805 2.314 1.703-.001-2.554-1.121-.803.583-.813 1.538 1.093.002 17.513h1l.002-17.516 1.544-1.09.583.813-1.131.81.001 2.554 2.323-1.71.592.805-2.915 2.147v2.963l4.135-3.016.589.808-4.724 3.446v3.881z"/>

  </svg>
)

export default LogoSVG;
