const FormatUtil = {
    msToTimeStr(ms) {
        let ss = Math.round((ms / 1000) % 60);
        let mm = Math.floor((ms / 1000 / 60) % 60);
        let str = undefined;
        if (ss < 10) {
            ss = "0" + ss;
        }
        if (mm < 10) {
            mm = "0" + mm;
        }
        if (ms === 0)
            str = "00:00";
        return (str) ? str : mm + ":" + ss;
    },
    getTimeStrFromMilliseconds(ms) {
        let ss = Math.round((ms / 1000) % 60);
        let mm = Math.floor((ms / 1000 / 60) % 60);
        let str = "0s";

        if (mm < 1 && ss > 0)
            str = ss + "s";
        else if (ss > 0)
            str = mm + "m " + ss + "s";

        return str;
    }
};

export default FormatUtil;
