import React from "react"
import style from "../svg.module.scss"

const width = 24;
const height = 24;

const SvgNormalScreen = (props) => (
  <svg viewBox="0 0 24 24"
       height={height} width={width}
       className={style.svg + " " + props.className}
       xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink"
       fillRule="evenodd" clipRule="evenodd"
       shapeRendering="geometricPrecision">

    <path d="M18 3h2v4h4v2h-6v-6zm6 12v2h-4v4h-2v-6h6zm-18 6h-2v-4h-4v-2h6v6zm-6-12v-2h4v-4h2v6h-6z"/>

  </svg>
)

export default SvgNormalScreen;
