import React, {Component} from "react"

import style from "./PlayerControlsProgress.module.scss";
import HBox from "../../../../../bosom/components/layout/boxes/HBox";
import VBox from "../../../../../bosom/components/layout/boxes/VBox";
import FormatUtil from "../../../../../bosom/utils/FormatUtil";
import MiscUtil from "../../../../../bosom/utils/MiscUtil";
import ActivityModel from "../../../model/ActivityModel";

class PlayerControlsProgress extends Component {
    constructor(props) {
        super(props);
        this.model = ActivityModel.instance;
;        this.state = {
            progressTime: 0,
            tickTime: 0,
            tickCounter: 0,
            progressValue: 0,
        }
        this.timeoutUID = 0;
        this._totalTimeLb = null;
        this.tickHandler = this.tickHandler.bind(this);
        this.onStateChanging = this.onStateChanging.bind(this);
        this.onNotification = this.onNotification.bind(this);
        this.onIsPlayingChange = this.onIsPlayingChange.bind(this);
        this.onRestartRequested = this.onRestartRequested.bind(this);

        this.model.configure(this, ["isPlaying", "stepIndex", "restartRequested", "entityVO"])
    }

    onComponentDidMount(){
        const totalLb = (this.state.entityVO) ? FormatUtil.msToTimeStr(this.state.entityVO.totalExecutionTime) : "0:00";
        this.setState({totalTimeLb: totalLb});
    }

    onNotification(propName) {
        switch(propName) {
            case "restartRequested": this.onRestartRequested(); break;
        }
    }

    onStateChanging(propName, propValue) {
        switch(propName) {
            case "isPlaying": this.onIsPlayingChange(propValue); break;
            case "stepIndex": this.onStepIndexChange(); break;
        }
    }

    onStepIndexChange() {
        const totalSteps = (this.state.entityVO) ? this.state.entityVO.totalNumberOfSteps : 0;
        const absoluteStepIndex = this.model.absoluteStepIndex;
        const val = (absoluteStepIndex * 100) / totalSteps;
        this.setState({progressValue: val});
    }

    onIsPlayingChange(val) {
        this.tickHandler();
    }

    onRestartRequested(val) {
        this.setState({tickTime: 0, progressTime: 0, tickCounter: 0});
        this.tickHandler();
    }

    tickHandler() {
        clearTimeout(this.timeoutUID);
        if (this.model.isPlaying && this.mounted) {
            this.timeoutUID = MiscUtil.callLater(this.tickHandler, 1000, this.timeoutUID, "PlayerControlsProgress");
            this.onTick();
        }
    }

    onTick() {
        if(this.mounted){
            let counter = this.state.tickCounter;
            const totalSteps = (this.state.entityVO) ? this.state.entityVO.totalNumberOfSteps : 0;
            const absoluteStepIndex = this.model.absoluteStepIndex;
            let tickTime = this.state.tickTime + 1000;
            if (counter > 2) {
                counter = 0;
                //adjuster:
                const execTime = (this.state.entityVO) ? this.state.entityVO.totalExecutionTime : 0;
                tickTime = (absoluteStepIndex * execTime) / totalSteps;
            }
            this.setState({tickTime: tickTime, tickCounter: ++counter});
        }
    }

    getRepetitionLb() {
        const repIdx = this.model.repetitionIndex + 1;
        const repTotal = (this.state.entityVO) ? this.state.entityVO.number_of_repetitions : 0;
        return <label className={style.rep_lb}>Repetition <strong>{repIdx}</strong> out
            of <strong>{repTotal}</strong></label>;
    }

    get totalTimeLb(){
        if(!this._totalTimeLb){
            this._totalTimeLb = FormatUtil.msToTimeStr(this.state.entityVO.totalExecutionTime);
        }
        return this._totalTimeLb;
    }

    getRepChunks(){
        const repTotal = (this.state.entityVO) ? this.state.entityVO.number_of_repetitions : 0;
        const list = [];
        for(let i = 0; i < repTotal; i++){
            list.push(<div className={style.rep_chunk} key={"rep_chunk_" + i}/>);
        }
        return list;
    }

    render() {
        const repLb = this.getRepetitionLb();
        const progressTimeLb = FormatUtil.msToTimeStr(this.state.tickTime);
        const timeout = this.model.timeout / 1000;
        const totalTimeLb = this.totalTimeLb;

        const progressStyle = {
            width: this.state.progressValue + "%",
            transition: "width " + timeout + "s"
        };
        if (!this.state.isPlaying) {
            progressStyle.transition = "none";
        }

        const repChunks = this.getRepChunks();

        return (
            <HBox className={style.parent + " " + this.props.className}>

                <label className={style.time_lb}>{progressTimeLb}</label>

                <VBox className={style.track_wrapper}>
                    {repLb}
                    <HBox className={style.track}>
                        {repChunks}
                        <div className={style.progress_track} style={progressStyle}/>
                    </HBox>
                </VBox>

                <label className={style.time_lb}>{totalTimeLb}</label>

            </HBox>
        )
    }
}

export default PlayerControlsProgress;
