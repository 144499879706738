import {SvgLoader, SvgProxy} from "react-svgmt";
import style from "./SvgDisplay.module.scss";
import React from "react";
import ActivityModel from "../../../model/ActivityModel";
import CoreEnums from "../../../../../bosom/data/enums/CoreEnums";

const getStaticSvgProxies = function(entityVO, stepIdx){
    const proxies = [];
    const n = entityVO.number_of_steps - 1;
    const direction = (stepIdx === 0) ? CoreEnums.INCLINE : ActivityModel.instance.direction;
    let ascendingCls = style.arrow + " " + style.ascending + ((direction === CoreEnums.RELEASE) ? " " + style.moving_this_way : "");
    let descendingCls = style.arrow + " " + style.descending + ((direction === CoreEnums.INCLINE) ? " " + style.moving_this_way : "");

    proxies.push(<SvgProxy shape-rendering="geometricPrecision" key={"towel"} selector="#towel" class={style.towel} />);
    proxies.push(<SvgProxy shape-rendering="geometricPrecision" key={"table"} selector="#table" class={style.table} />);
    proxies.push(<SvgProxy shape-rendering="geometricPrecision" key={"ascending"} selector="#ascending" class={ascendingCls} />);
    proxies.push(<SvgProxy shape-rendering="geometricPrecision" key={"descending"} selector="#descending" class={descendingCls} />);
    proxies.push(<SvgProxy shape-rendering="geometricPrecision" key={"step"} selector="g.step" class={style.step} />);

    let selectedCls = style.step + " " + style.selected_step;
    if(stepIdx === 0){
        selectedCls += " " + style.first_step;
    }
    else if(direction === CoreEnums.RELEASE){
        proxies.push(<SvgProxy shape-rendering="geometricPrecision" key={"step_first"} selector={"g#step_0"} class={style.step + " " + style.first_step} />);
    }

    if(stepIdx === n){
        selectedCls += " " + style.last_step;
    }
    else if(direction === CoreEnums.INCLINE){
        proxies.push(<SvgProxy shape-rendering="geometricPrecision" key={"step_last"} selector={"g#step_" + n} class={style.step + " " + style.last_step} />);
    }

    if(ActivityModel.instance.holding){
        selectedCls += " " + style.holding;
    }

    proxies.push(<SvgProxy shape-rendering="geometricPrecision"
                           key={"step_selected_" + stepIdx}
                           selector={"g#step_" + stepIdx}
                           class={selectedCls} />);

    return proxies;
}

const SvgDisplayHelper = {
    getSvgElement() {
        this.model = ActivityModel.instance;
        const entityVO = this.model.entityVO;
        const stepIndex = this.model.stepIndex;
        if (this.model.exerciseSvgData) {// visibility: hidden;
            const stpIdx = (stepIndex === null) ? 0 : stepIndex;
            const steps = getStaticSvgProxies(entityVO, stpIdx);
            return <SvgLoader svgXML={this.model.exerciseSvgData} className={style.svg_elm}>{steps}</SvgLoader>;
        }

        return <div className={style.loader}>Loading...</div>;
    },

    getSvgText(){
        const holding = ActivityModel.instance.holding;
        if(holding){
            return <h1>Hello</h1>
        }
        return null;
    }
}

export default SvgDisplayHelper;
