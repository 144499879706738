import MiscUtil from "../utils/MiscUtil";

class Observer{
  constructor(){
    this.observers = {};
    this.timerUID = 0;
    this.notifyNameBuffer = {};
    this.timerNotify = this.timerNotify.bind(this);
  }

  observe(uid, propName, cb){
    if(!this.observers[propName]){
      this.observers[propName] = {};
    }
    if(!this.observers[propName][uid]) {
      this.observers[propName][uid] = {fn : cb};
    }
    else{
      //console.warn("Observer for " + propName + " with unique id of: " + uid + " already exists");
    }
  }

  unobserve(uid, propName){
    if(this.observers[propName]){
      if(this.observers[propName][uid]){
        delete this.observers[propName][uid];
      }
    }
  }

  flipValue(propName){
    this.setAndNotify(propName, !this.getValue(propName));
  }

  setAndNotify(name, value) {
    const modelValue = this.getValue(name);
    if (modelValue !== value) {
      this.setValue(name, value);
      this.notify(name);
    }
  }

  setValue(name, value) {
    this["_" + name] = value;
  }

  getValue(name) {
    return this["_" + name];
  }

  hasValue(name) {
    return this.hasOwnProperty("_" + name);
  }

  notify(propName) {
    clearTimeout(this.timerUID);
    this.notifyNameBuffer[propName] = propName;
    this.timerUID = MiscUtil.callLater(this.timerNotify, 10, this.timerUID, "Observer");
  }

  timerNotify() {
    const fnsToCall = [];
    for(const propName in this.notifyNameBuffer){
      if(this.observers.hasOwnProperty(propName)){
        const observerFunctions = this.observers[propName];
        for (let uid in observerFunctions) {
          if (observerFunctions.hasOwnProperty(uid)) {
            const fn = observerFunctions[uid].fn;
            const propValue = this.getValue(propName);
            fnsToCall.push({fn, propName, propValue});
          }
        }
      }
    }
    this.notifyNameBuffer = {};

    if(fnsToCall.length > 0){
      this.timerUID = MiscUtil.callLater(function(){
        for(let i = 0; i < fnsToCall.length; i++){
          const fnObj = fnsToCall[i];
          fnObj.fn(fnObj.propValue, fnObj.propName);
          if(Object.keys(this.notifyNameBuffer).length > 0){
            this.timerNotify();
          }
        }
      }.bind(this), 0, this.timerUID);
    }
  }
}

export default Observer;
