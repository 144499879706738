
const CoreEnums = {
  TRACK : "tracks",
  SESSION : "sessions",
  EXERCISE : "exercises",
  INCLINE : "incline",
  RELEASE : "release"
};

export default CoreEnums;
