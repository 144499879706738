import React from "react"
import style from "../svg.module.scss"

const width = 24;
const height = 24;

const PlaySVG = (props) => (
  <svg viewBox="0 0 24 24"
       height={height} width={width}
       className={style.svg_parent + " " + props.className}
       xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink"
       fillRule="evenodd" clipRule="evenodd">

    <path className={style.player} d="M3 22v-20l18 10-18 10z"/>/>

  </svg>
)

export default PlaySVG;
