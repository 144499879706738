import React, {Component} from "react";
import MiscUtil from "../../../bosom/utils/MiscUtil";
import ActivityModel from "../model/ActivityModel";

class ProgressController extends Component {
    constructor(props){
        super(props);
        this.model = ActivityModel.instance;
        this.entityVO = props.entityVO;
        this.reset();
        this.onPlayTick = this.onPlayTick.bind(this);
        this.onStateChanging = this.onStateChanging.bind(this);
        this.onNotification = this.onNotification.bind(this);

        this.model.configure(this, ["isPlaying", "restartRequested", "exerciseSvgData"]);
    }

    onNotification(propName) {
        switch(propName) {
            case "restartRequested": this.restartRequest(); break;
        }
    }

    onStateChanging(propName, propValue) {
        switch(propName) {
            case "isPlaying": this.playTick(); break;
            case "exerciseSvgData": this.onSvgDataLoaded(); break;
        }
    }

    onSvgDataLoaded(){
        if(this.model.firstLoad){
            this.model.firstLoad = false;
        }
        else if(this.model.doAutoplay){
            this.model.doAutoplay = false;
            this.model.showAutoPlayMessage = true;
        }
    }

    clearTimer(){
        clearTimeout(this.timerUID);
        this.timerUID = null;
    }

    reset(){
        this.clearTimer();
        this.model.isDone = false;
        this.model.repetitionIndex = this.model.prevIndex = this.model.stepIndex = 0;
    }

    restartRequest(){
        this.reset();
        this.playTick();
    }

    playTick(timeoutOverride){
        if(this.mounted && this.model.isPlaying && !this.timerUID) {
            if(this.model.isDone){
                this.reset();
            }
            this.timerUID = MiscUtil.callLater(this.onPlayTick, timeoutOverride || this.model.timeout, this.timerUID, "StepController");
        }
    }

    onPlayTick(){
        this.clearTimer();
        if(this.model.isPlaying){
            if(this.model.isAtBottom){
                const nextRepIdx = this.model.repetitionIndex + 1;
                //if(nextRepIdx < this.entityVO.number_of_repetitions){
                if(nextRepIdx >= this.entityVO.number_of_repetitions){
                    this.model.isPlaying = false;
                    this.model.isDone = true;
                    return;
                }
                this.model.repetitionIndex = nextRepIdx;
            }

            this.model.stepIndex = this.model.nextIndex;
            this.playTick();
        }
    }

    render() {return (<></>)}
}

export default ProgressController;
