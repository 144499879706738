import React from "react"
import style from "../../svg.module.scss"

const width = 25;
const height = 25;

const SittingSVG = (props) => (
  <svg viewBox="0 0 199.216 199.216"
       height={height} width={width}
       className={style.svg_parent + " " + props.className}
       xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink"
       fillRule="evenodd" clipRule="evenodd">

    <path className={style.meta} d="M128.005,40.172c11.24,0.336,20.621-8.385,20.951-19.476c0.33-11.088-8.512-20.351-19.752-20.687
			c-11.234-0.335-20.617,8.385-20.947,19.471C107.925,30.573,116.771,39.836,128.005,40.172z"/>
    <path className={style.meta} d="M150.579,56.893c0.274-2.674-0.599-5.338-2.397-7.334s-4.361-3.146-7.051-3.146H115.57c-4.153,0-7.629,3.155-8.028,7.289
			l-6.431,66.48H63.127c-3.86,0-7.562,1.535-10.289,4.267c-2.727,2.732-4.257,6.437-4.251,10.297l0.081,53.626
			c0.01,6.008,4.882,10.845,10.887,10.845c0.005,0,0.013,0,0.019,0c6.013,0,10.879-4.886,10.871-10.899l-0.065-43.052
			c-0.001-0.888,0.351-1.74,0.979-2.369c0.627-0.628,1.479-0.98,2.366-0.98h58.345c6.013,0,10.254-4.589,10.889-10.882
			C143,130.616,148.259,79.451,150.579,56.893z"/>
  </svg>
)

export default SittingSVG;
