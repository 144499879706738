import React, {Component} from "react"

import style from "./SvgPreviewLoader.module.scss";
import {SvgLoader} from "react-svgmt";
import FetchUtil from "../../../../bosom/utils/FetchUtil";
import MiscUtil from "../../../../bosom/utils/MiscUtil";

class SvgPreviewLoader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            previewXML : null
        }

        this.onFetchComplete = this.onFetchComplete.bind(this);
    };

    getSvgElm() {
        if (this.state.previewXML) {// visibility: hidden;
            const x = MiscUtil.getRandomNumber(1, 9999999);
            return <SvgLoader key={"key_" + x}
                              svgXML={this.state.previewXML}
                              className={style.svg_elm}/>;
        }
        else if(this.props.svgPath && !this.fetching){
            this.fetching = true;
            FetchUtil.fetchSVG(this.props.svgPath, this.onFetchComplete);
        }
        return <div className={style.loader + " " + style.loader_elm}>Loading...</div>;
    }

    onFetchComplete(svgString){
        this.setState({previewXML : svgString});
    }

    render() {
        const cls = style.parent + ((this.props.className) ? " " + this.props.className : "");

        return (
            <div className={cls}>
                {this.getSvgElm()}
            </div>
        )
    }
}

export default SvgPreviewLoader;
