import React from "react";
import StandingSVG from "../../_ARCHIVE_/assets/svgs/meta/position/StandingSVG"
import SittingSVG from "../../_ARCHIVE_/assets/svgs/meta/position/SittingSVG"
import LyingSVG from "../../_ARCHIVE_/assets/svgs/meta/position/LyingSVG"
import ThumbSVG from "../../_ARCHIVE_/assets/svgs/meta/body_areas/ThumbSVG"
import WristSVG from "../../_ARCHIVE_/assets/svgs/meta/body_areas/WristSVG"

const BodyEnums = {
  lower_back : {
    label : "Lower Back",
    svg : <StandingSVG/>
  },
  hip : {
    label : "Hip",
    svg : <SittingSVG/>
  },
  groin : {
    label : "Groin",
    svg : <LyingSVG/>
  },
  legs : {
    label : "Legs",
    svg : <LyingSVG/>
  },
  left_leg : {
    label : "Left Leg",
    svg : <LyingSVG/>
  },
  right_leg : {
    label : "Right Leg",
    svg : <LyingSVG/>
  },
  wrist : {
    label : "Wrist",
    tag_name : "Wrist",
    svg : <WristSVG/>
  },
  thumb : {
    label : "Thumb",
    svg : <ThumbSVG/>
  }
};

export default BodyEnums;
