import React, {Component} from "react"

import style from "./ActivityMeta.module.scss";
import ActivityModel from "../../model/ActivityModel";
import VBox from "../../../../bosom/components/layout/boxes/VBox";
import SvgSetup from "../../../../assets/svg/core/SvgSetup";
import SvgInstructions from "../../../../assets/svg/core/SvgInstructions";
import SvgDisclaimer from "../../../../assets/svg/core/SvgDisclaimer";

class ActivityMeta extends Component {
    constructor(props) {
        super(props);

        ActivityModel.instance.configure(this, ["entityVO"]);
        this.onStateChanging = this.onStateChanging.bind(this);
    }

    onStateChanging(propName, propValue){
        switch(propName){
            case "entityVO" : console.log("still works"); break;
        }
    }

    getInstructions(dp) {
        let lis = [];
        const n = (dp) ? dp.length : 0;
        const eKey = this.state.entityVO.key;
        Object.keys(dp).forEach( idx => {
            const item = dp[idx];
            lis.push(<li key={eKey + "_" + idx}>{item.text}</li>);
        });
        return lis;
    }


    render() {
        const eVO = this.state.entityVO;
        const setupTxt = (eVO) ? eVO.meta_data.setup.text : "";
        const disclaimerTxt = (eVO) ? eVO.meta_data.disclaimer.text : "";
        const lis = (eVO) ? this.getInstructions(eVO.meta_data.instructions) : "";
        return (
            <VBox className={style.parent}>
                <h4 className={style.meta_heading}><SvgSetup/>Setup:</h4>
                <p className={style.meta_paragraph}>{setupTxt}</p>
                <h4 className={style.meta_heading}><SvgInstructions/>Instructions:</h4>
                <ul className={style.meta_ul}>{lis}</ul>
                <h4 className={style.meta_heading}><SvgDisclaimer/>Disclaimer:</h4>
                <p className={style.meta_paragraph}>{disclaimerTxt}</p>
            </VBox>
        )
    }
}

export default ActivityMeta;
