import SupportEnum from "../data/enums/SupportEnum";
import EquipmentEnums from "../data/enums/EquipmentEnums";
import PositionEnums from "../data/enums/PositionEnums";
import BodyEnums from "../data/enums/BodyEnums";
import CategoryEnum from "../data/enums/CategoryEnum";
import IntensityEnums from "../data/enums/IntensityEnums";

class VOBase {
  constructor (jsonObj) {
    this.setData(jsonObj);
  };

  setData(dataProvider){
    for(const key in dataProvider){
      if(dataProvider.hasOwnProperty(key)){
        if(key === "template")
          continue;
        let val = dataProvider[key];
        if(key === "support"){
          val = this.getFromEnums(val, SupportEnum);
        }
        else if(key === "equipment"){
          val = this.getFromEnums(val, EquipmentEnums);
        }
        else if(key === "position"){
          val = this.getFromEnums(val, PositionEnums);
        }
        else if(key === "target_area"){
          val = this.getFromEnums(val, BodyEnums);
        }
        else if(key === "category"){
          val = this.getFromEnums(val, CategoryEnum);
        }
        else if(key === "intensity" || key === "release_intensity"){ // // expecting single value
          val = this.getFromEnums(val, IntensityEnums);
        }
        this[key] = val;
      }
    }
  }

  getFromEnums(input, enumObj, hasManyKeys){
    if(!Array.isArray(input)){
      input = [input];
    }
    const n = input.length;
    let output = {};
    for(let i = 0; i < n; i++){
      const key = input[i];
      if(hasManyKeys){
        output[key] = enumObj[key];
      }
      else{
        output = enumObj[key];
      }

    }
    return output;
  }
}

export default VOBase;
