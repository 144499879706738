import React, {Component} from "react"

import style from "./PlayerControls.module.scss";
import HBox from "../../../../../bosom/components/layout/boxes/HBox";
import PlaySVG from "../../../../../bosom/_ARCHIVE_/assets/svgs/player/PlaySVG";
import PauseSVG from "../../../../../bosom/_ARCHIVE_/assets/svgs/player/PauseSVG";
import RestartSVG from "../../../../../bosom/_ARCHIVE_/assets/svgs/player/RestartSVG";
import DivButton from "../../../../../bosom/components/elements/buttons/div-button/DivButton";
import NextSVG from "../../../../../bosom/_ARCHIVE_/assets/svgs/player/NextSVG";
import PlayerControlsProgress from "./PlayerControlsProgress";
import ActivityModel from "../../../model/ActivityModel";
import SvgFullScreen from "../../../../../assets/svg/core/SvgFullScreen";
import SvgNormalScreen from "../../../../../assets/svg/core/SvgNormalScreen";

class PlayerControls extends Component {
    constructor(props) {
        super(props);
        this.model = ActivityModel.instance;
        this.model.configure(this, ["isPlaying", "isDone", "exerciseSvgData", "isMaximized"]);
        this.onPlayPauseClick = this.onPlayPauseClick.bind(this);
        this.onRestartClick = this.onRestartClick.bind(this);
        this.onSizeClick = this.onSizeClick.bind(this);
    }

    onPlayPauseClick() {
        this.model.flipValue("isPlaying");
    }

    onRestartClick() {
        this.model.notify("restartRequested");
    }

    onSizeClick() {
        this.model.flipValue("isMaximized");
    }

    render() {
        const showControls = (this.state.exerciseSvgData);
        const playPauseBtn = this.state.isPlaying ? <PauseSVG className={style.svg}/> : <PlaySVG className={style.svg}/>;
        const sizeBtn = this.state.isMaximized ?  <SvgNormalScreen className={style.svg}/> : <SvgFullScreen className={style.svg}/>;
        let cls = style.parent + ((this.state.isDone) ? " " + style.is_done : "");
        cls += (showControls) ? "" : " " + style.hidden;
        return (
            <HBox className={cls + " " + this.props.className}>

                <DivButton className={style.restart_btn} onClick={this.onRestartClick}>
                    <RestartSVG className={style.svg}/>
                </DivButton>

                <DivButton className={style.play_pause_btn} onClick={this.onPlayPauseClick}>
                    {playPauseBtn}
                </DivButton>

                <PlayerControlsProgress/>

                <DivButton className={style.size_btn} onClick={this.onSizeClick}>
                    {sizeBtn}
                </DivButton>

                <DivButton className={style.next_btn}>
                    <NextSVG className={style.svg}/>
                </DivButton>

            </HBox>
        )
    }
}

export default PlayerControls;
