import React from "react"
import style from "../svg.module.scss"

const width = 35;
const height = 35;

const ArrowRightSVG = (props) => (
  <svg viewBox="0 0 24 24"
       height={height} width={width}
       className={style.svg_parent + " " + props.className}
       xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink"
       fillRule="evenodd" clipRule="evenodd">

    <path className={style.logo} d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/>

  </svg>
)

export default ArrowRightSVG;
