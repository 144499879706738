import React from "react"
import style from "../../svg.module.scss"

const width = 25;
const height = 25;

const StandingSVG = (props) => (
  <svg viewBox="0 0 197.86 197.86"
       height={height} width={width}
       className={style.svg_parent + " " + props.className}
       xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink"
       fillRule="evenodd" clipRule="evenodd">

    <ellipse className={style.meta} cx="98.946" cy="16.539" rx="16.759" ry="16.539"/>
    <path className={style.meta} d="M119.96,36.707c-0.861-0.492-2.061-0.788-3.469-0.788c-9.271,0-26.057-0.13-35.462-0.13c-1.16,0-2.433,0.338-3.126,0.734
			c-27.72,15.806-15.346,65.125-14.806,67.217c0.696,2.693,3.118,4.605,5.777,4.605c0.495,0,0.995-0.062,1.494-0.188
			c3.195-0.824,5.148-4.208,4.328-7.403c-2.678-10.431-6.376-37.178,4.259-49.716L78.83,188.896c0,4.949,4.04,8.963,8.99,8.963
			c4.951,0,8.991-4.014,8.991-8.963v-67.678h4.305v67.678c0,4.949,3.976,8.963,8.926,8.963c4.951,0,8.928-4.014,8.928-8.963V51.267
			c10.51,12.558,6.878,39.251,4.204,49.667c-0.825,3.195,1.103,6.455,4.298,7.281c0.5,0.129,1.007,0.19,1.502,0.19
			c2.659,0,5.087-1.788,5.785-4.479C135.298,101.831,147.681,52.513,119.96,36.707z"/>
  </svg>
)

export default StandingSVG;
