import React from "react"
import style from "../../svg.module.scss"

const width = 25;
const height = 25;

const ThumbSVG = (props) => (
  <svg viewBox="0 0 24 24"
       height={height} width={width}
       className={style.svg_parent + " " + props.className}
       xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink"
       fillRule="evenodd" clipRule="evenodd">

        <path className={style.meta} d="M19.406 9.558c-1.21-.051-2.87-.278-3.977-.744.809-3.283 1.253-8.814-2.196-8.814-1.861 0-2.35 1.668-2.833 3.329-.373 1.283-.794 2.341-1.254 3.22-.038-.643-.475-1.611-.928-2.08l-.796.625c.39.328.835 1.352.816 1.906-.561.138-1.404-.067-1.91-.344l-.256.967c.557.266 1.557.491 2.164.377-1.842 2.44-4.1 2.794-6.236 3.072v9.928c3.503 0 5.584.729 8.169 1.842 1.257.541 3.053 1.158 5.336 1.158 2.538 0 4.295-.997 5.009-3.686.5-1.877 1.486-7.25 1.486-8.25 0-1.648-1.168-2.446-2.594-2.506zm-6.07-.597l-2.601-1.05c.198-.37.389-.772.575-1.201l2.359.778c-.1.5-.213.989-.333 1.473zm.505-2.518l-2.138-.705c.148-.396.292-.816.432-1.259l1.833.548c-.021.487-.062.961-.127 1.416zm.144-2.527l-1.529-.457c.773-2.332 1.429-1.537 1.529.457zm3.911 10.501s.201.01 1.069-.027c1.082-.046 1.051 1.469.004 1.563l-1.761.099c-.734.094-.656 1.203.141 1.172 0 0 .686-.017 1.143-.041 1.068-.056 1.016 1.429.04 1.551-.424.053-1.745.115-1.745.115-.811.072-.706 1.235.109 1.141l.771-.031c.822-.074 1.003.825-.292 1.661-1.567.881-4.685.131-6.416-.614-2.239-.965-4.438-1.934-6.959-2.006v-6c2.257-.518 4.419-1.401 6.197-4.164 1.365.705 5.609 2.524 9.002 2.714 1.055.059 1.024 1.455-.051 1.584l-1.394.167s-.608 1.111.142 1.116z"/>

  </svg>
)

export default ThumbSVG;
