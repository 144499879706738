import React, {Component} from "react"
import style from "./ActivityFooter.module.scss";
import VBox from "../../../../bosom/components/layout/boxes/VBox";
import HBox from "../../../../bosom/components/layout/boxes/HBox";
import ActivityModel from "../../model/ActivityModel";
import FormatUtil from "../../../../bosom/utils/FormatUtil";
import DivButton from "../../../../bosom/components/elements/buttons/div-button/DivButton";
import SvgShare from "../../../../assets/svg/core/SvgShare";
import SvgRepetitions from "../../../../assets/svg/core/SvgRepetitions";
import SvgTime from "../../../../assets/svg/core/SvgTime";
import DefaultToggle from "../../../../bosom/components/elements/toggle/default/DefaultToggle";
import MiscUtil from "../../../../bosom/utils/MiscUtil";
import {Link} from "gatsby";

class ActivityFooter extends Component {
    constructor(props) {
        super(props);
        this.model = ActivityModel.instance;
        this.model.configure(this, ["entityVO", "markDone"])
        this.onMarkDoneToggle = this.onMarkDoneToggle.bind(this);
    }

    getViewsLb(){
        const eVO = this.model.entityVO;
        return "30,123 views";
    }

    onMarkDoneToggle() {
        this.model.flipValue("markDone");
    }

    getMetaItems(){
        const list = [];
        const nextVO = this.state.entityVO;
        if(nextVO){
            list.push(this.getSVGItem(nextVO.category));
            list.push(this.getSVGItem(nextVO.intensity));
            list.push(this.getSVGItem(nextVO.equipment));
            list.push(this.getSVGItem(nextVO.target_area));
        }
        return list;
    }

    getSVGItem(obj){
        if(!obj || !obj.tag_name)
            return null;
        const randKey = MiscUtil.getRandomNumber(0,9999999);
        const tagName =  obj.tag_name.toLowerCase();
        const toPath = "/tags/" + tagName;
        return <Link to={toPath} key={randKey} className={style.tag_item}>
            {tagName}
        </Link>;
    }

    render() {
        const eVO = this.state.entityVO;
        const title = (eVO) ? eVO.meta_data.title.text : "";
        const summary = (eVO) ? eVO.meta_data.summary.text : "";
        const repCount = (eVO) ? eVO.number_of_repetitions : "";
        const durationStr = (eVO) ? FormatUtil.getTimeStrFromMilliseconds(eVO.totalExecutionTime) : "";
        const viewsLb = this.getViewsLb();
        const metaItems = this.getMetaItems();

        return (
            <VBox className={style.parent}>
                <HBox className={style.info_wrapper}>
                    <HBox className={style.options_wrapper}>
                        <label className={style.meta_item}>{viewsLb}</label>
                        <div className={style.meta_item + " " + style.level_2}>
                            <SvgRepetitions/>
                            <span>{repCount}</span>
                        </div>
                        <div className={style.meta_item + " " + style.last_item + " " + style.level_2}>
                            <SvgTime/>
                            <span>{durationStr}</span>
                        </div>
                    </HBox>
                    <HBox className={style.actions_wrapper}>
{/*                        <DivButton className={style.meta_item  + " " + style.share}>
                            <SvgShare/>
                            <span>Share</span>
                        </DivButton>*/}
                        <DefaultToggle label={"Mark Done"}
                                       className={style.meta_item + " " + style.last_item}
                                       onToggle={this.onMarkDoneToggle}
                                       checked={this.state.markDone}/>
                    </HBox>
                </HBox>
                <h4 className={style.activity_title}>{title}</h4>
                <p className={style.activity_summary}>{summary}</p>
                <HBox className={style.footer_wrapper}>
                    {metaItems}
                </HBox>
            </VBox>
        )
    }
}

export default ActivityFooter;
