import MiscUtil from "../utils/MiscUtil";
import Observer from "./Observer";

class StateAssistObserver extends Observer{
  constructor(){
    super();
    this.observers = {};
    this.timerUID = 0;
    this.notifyNameBuffer = {};
    this.timerNotify = this.timerNotify.bind(this);
  }

  configure(compClassInstance, observerPropNames) {
    compClassInstance.uid = compClassInstance.constructor.name + "_" + MiscUtil.createUUID();
    compClassInstance.propsToObserve = observerPropNames;
    compClassInstance.configureObservers = this.getConfigureObserversFunction(compClassInstance);
    compClassInstance.componentDidMount = this.getComponentDidMountFunction(compClassInstance);
    compClassInstance.componentWillUnmount = this.getComponentWillUnmountFunction(compClassInstance);
    compClassInstance.dirtyStates = [];
    this.configureState(compClassInstance, observerPropNames);
  }

  configureState(compClassInstance, observerPropNames){
    if(!compClassInstance.state){
      compClassInstance.state = {};
    }
    const n = observerPropNames ? observerPropNames.length : 0;
    for (let i = 0; i < n; i++) {
      const propName = observerPropNames[i];
      compClassInstance.state[propName] = this.getValue(propName);
    }
  }

  getConfigureObserversFunction(inst) {
    const _model = this;
    return function(mounted){
      const n = inst.propsToObserve ? inst.propsToObserve.length : 0;
      for (let i = 0; i < n; i++) {
        const propName = inst.propsToObserve[i];
        if (mounted)
          _model.observe(inst.uid, propName, _model.getPropChangedFunction(inst));
        else
          _model.unobserve(inst.uid, propName);
      }
      inst.mounted = mounted;
    }

  }

  getComponentDidMountFunction(inst) {
    return function(){
      inst.configureObservers(true);
      if(inst.hasOwnProperty("onComponentDidMount")){
        inst.onComponentDidMount();
      }
    }
  }

  getComponentWillUnmountFunction(inst) {
    return function(){
      inst.configureObservers(false);
      if(inst.hasOwnProperty("onComponentWillUnmount")){
        inst.onComponentWillUnmount();
      }
    }
  }

  getPropChangedFunction(inst) {
    return function (propValue, propName) {
      if(propValue === undefined && this.hasOwnProperty("onNotification")) { // this is just a notification...
        this.onNotification(propName);
      }
      else if (this.state[propName] !== propValue) {
        if (this.mounted) {
          this.setState({[propName]: propValue});
          if (this.hasOwnProperty("onStateChanging"))
            this.onStateChanging(propName, propValue);
        } else {
          this.dirtyStates.push({[propName]: propValue});
        }
      }
    }.bind(inst);
  }
}

export default StateAssistObserver;
