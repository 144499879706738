import CoreEnums from "../../../bosom/data/enums/CoreEnums";
import StateAssistObserver from "../../../bosom/base/StateAssistObserver";

let _inst = null;

class ActivityModel extends StateAssistObserver {
    static get instance() {
        if (!_inst){
            _inst = new ActivityModel();
            //default
            _inst.isIfoVisible = true;
            _inst.autoplay = true;
            _inst.markDone = true;
            _inst.firstLoad = true;
        }

        return _inst
    }

    reset(){
        this.setValue("nextVO", undefined);
        this.setValue("entityVO", undefined);
        this.setValue("exerciseSvgData", undefined);
        this.setValue("holding", undefined);
        this.setValue("isPlaying", undefined);
        this.setValue("isDone", undefined);
        this.setValue("restartRequested", undefined);
        this.setValue("repetitionIndex", undefined);
        this.setValue("prevIndex", undefined);
        this.setValue("stepIndex", undefined);
        this.setValue("playerOverlayElm", undefined);
        this.setValue("playerOverlaySvg", undefined);
    }

    get playerOverlayElm() {return this.getValue("playerOverlayElm");}
    set playerOverlayElm(val) {this.setAndNotify("playerOverlayElm", val);}

    get playerOverlaySvg() {return this.getValue("playerOverlaySvg");}
    set playerOverlaySvg(val) {this.setAndNotify("playerOverlaySvg", val);}

    get isMaximized() {return this.getValue("isMaximized");}
    set isMaximized(val) {this.setAndNotify("isMaximized", val);}

    get didAutoplay() {return this.getValue("didAutoplay");}
    set didAutoplay(val) {this.setAndNotify("didAutoplay", val);}

    get holding() {return this.getValue("holding");}
    set holding(val) {this.setAndNotify("holding", val);}

    get markDone() {return this.getValue("markDone");}
    set markDone(val) {this.setAndNotify("markDone", val);}

    get autoplay() {return this.getValue("autoplay");}
    set autoplay(val) {this.setAndNotify("autoplay", val);}

    get firstLoad() {return this.getValue("firstLoad");}
    set firstLoad(val) {this.setAndNotify("firstLoad", val);}

    get showAutoPlayMessage() {return this.getValue("showAutoPlayMessage");}
    set showAutoPlayMessage(val) {this.setAndNotify("showAutoPlayMessage", val);}

    get isTOCVisible() {return this.getValue("isTOCVisible");}
    set isTOCVisible(val) {this.setAndNotify("isTOCVisible", val);}

    get isIfoVisible() {return this.getValue("isIfoVisible"); }
    set isIfoVisible(val) {  this.setAndNotify("isIfoVisible", val);  }

    get isPlaying() {return this.getValue("isPlaying"); }
    set isPlaying(val) {if(this.exerciseSvgData){this.setAndNotify("isPlaying", val);}}

    get isDone() {  return this.getValue("isDone");}
    set isDone(val) {if(val){this.holding = false;} this.setAndNotify("isDone", val);}

    get restartRequested() { return this.getValue("restartRequested"); }
    set restartRequested(val) { this.setAndNotify("restartRequested", val); }

    get entityVO() {return this.getValue("entityVO");}
    set entityVO(val) {this.setAndNotify("entityVO", val);}

    get nextVO() {return this.getValue("nextVO");}
    set nextVO(val) {this.setAndNotify("nextVO", val);}

    get exerciseSvgData() {return this.getValue("exerciseSvgData");}
    set exerciseSvgData(val) {this.setAndNotify("exerciseSvgData", val);}

    get repetitionIndex() {return this.getValue("repetitionIndex"); }
    set repetitionIndex(val) {  this.setAndNotify("repetitionIndex", val); }

    get prevIndex() {return this.getValue("prevIndex"); }
    set prevIndex(val) {this.setAndNotify("prevIndex", val); }

    get stepIndex() {return this.getValue("stepIndex"); }
    set stepIndex(val) {this.prevIndex = this.stepIndex; this.setAndNotify("stepIndex", val);}

    //Getters:
    get isOnTop() { return (this.stepIndex === this.totalSteps - 1); }
    get isAtBottom() { return (this.stepIndex === 0 && this.prevIndex > 0); }
    get direction() {return (this.stepIndex < this.prevIndex) ? CoreEnums.RELEASE : CoreEnums.INCLINE;}
    get totalSteps() {return this.entityVO.number_of_steps; }
    get timeout() {
        if(!this.entityVO)
            return 1;
        let timeouts = (this.direction === CoreEnums.RELEASE) ? this.entityVO.releaseTimeouts : this.entityVO.inclineTimeouts;
        let timeout = timeouts[this.stepIndex];
        if (this.isAtBottom && this.entityVO.wait_time_at_bottom > 0) {
            timeout += this.entityVO.wait_time_at_bottom;
            this.holding = true;
        } else if (this.isOnTop && this.entityVO.wait_time_at_top > 0) {
            timeout += this.entityVO.wait_time_at_top;
            this.holding = true;
        } else {
            this.holding = false;
        }
        if (isNaN(timeout)) {
            console.error("step time undefined!!!");
            timeout = 100;
        }

        return timeout;//(this.holding) ? 100 : 50; // timeout;
    }
    get nextIndex() {
        if (this.stepIndex === 0 || (this.stepIndex > this.prevIndex && this.stepIndex + 1 < this.totalSteps)) {
            return this.stepIndex + 1;
        }
        return this.stepIndex - 1;
    }
    get absoluteStepIndex() {
        let aStepIdx = this.stepIndex;
        const numOfSteps = this.entityVO.number_of_steps - 1;
        if (this.direction === CoreEnums.RELEASE) {
            aStepIdx = numOfSteps + (numOfSteps - this.stepIndex);
        }
        return aStepIdx + ((numOfSteps * 2) * this.repetitionIndex);
    }
    get progressValue(){
        return (this.absoluteStepIndex * 100) / this.entityVO.number_of_steps;
    }
}

export default ActivityModel;
