import React from "react";
import IntensityLowSVG from "../../_ARCHIVE_/assets/svgs/meta/intensity/IntensityLowSVG"
import IntensityMediumSVG from "../../_ARCHIVE_/assets/svgs/meta/intensity/IntensityMediumSVG"
import IntensityHighSVG from "../../_ARCHIVE_/assets/svgs/meta/intensity/IntensityHighSVG"

const IntensityEnums = {
  low : {
    label : "Low",
    tag_name : "Low-Intensity",
    time : 400,
    svg : <IntensityLowSVG/>
  },
  medium : {
    label : "Medium",
    tag_name : "Medium-Intensity",
    time : 300,
    svg : <IntensityMediumSVG/>
  },
  high : {
    label: "High",
    tag_name : "High-Intensity",
    time: 200,
    svg: <IntensityHighSVG/>
  }
};

export default IntensityEnums;
