import React from "react"
import style from "../../svg.module.scss"

const width = 25;
const height = 25;

const IntensityHighSVG = (props) => (
  <svg viewBox="0 0 100 100"
       height={height} width={width}
       className={style.svg_parent + " " + style.intensity_svg + " " + props.className}
       xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink"
       fillRule="evenodd" clipRule="evenodd">

      <line data-value={"filled"} x1="10" x2="10" y1="100" y2="80" className={style.filled}/>
      <line data-value={"filled"} x1="30" x2="30" y1="100" y2="60" className={style.filled}/>
      <line data-value={"filled"} x1="50" x2="50" y1="100" y2="40" className={style.filled}/>
      <line data-value={"filled"} x1="70" x2="70" y1="100" y2="20" className={style.filled}/>
      <line x1="90" x2="90" y1="100" y2="0" />
  </svg>
)

export default IntensityHighSVG;
