import React, {Component} from "react"

import style from "./PlayerTextOverlay.module.scss";
import ActivityModel from "../../../model/ActivityModel";

class PlayerTextOverlay extends Component {
    constructor(props) {
        super(props);
        this.model = ActivityModel.instance;
        this.model.configure(this, ["playerOverlayElm", "playerOverlaySvg"]);
    }

    render() {
        const elm = this.state.playerOverlayElm || null;
        const svg = this.state.playerOverlaySvg || null;
        let cls = (elm) ? " " + style.show : "";
        cls += (!svg) ? " " + style.no_svg : "";
        return (
            <div className={style.parent + cls}>
                {svg}{elm}
            </div>
        )
    }
}

export default PlayerTextOverlay;
