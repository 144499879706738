import React from "react"
import style from "../svg.module.scss"

const width = 24;
const height = 24;

const SvgHold = (props) => (
  <svg viewBox="0 0 24 24"
       height={height} width={width}
       className={style.svg + " " + props.className}
       xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink"
       fillRule="evenodd" clipRule="evenodd"
       shapeRendering="geometricPrecision">

    <path d="M20.825 12c-.228.59-3.052 6.998-3.772 8.614-.756 1.695-2.229 3.386-4.951 3.386h-4.729c-2.865 0-4.373-1.7-4.373-4.673v-12.975c0-1.721 2.37-1.633 2.37-.08v5.689c0 .468.858.465.858 0v-9.142c0-1.769 2.65-1.722 2.65 0v8.63c0 .476.797.456.797-.01v-10.128c0-1.722 2.624-1.773 2.624 0l.001 10.245c0 .459.826.469.826 0v-8.604c0-1.629 2.873-1.679 2.873 0v9.75c0 .597.587.692.811.236.212-.433 1.089-2.368 1.1-2.389.883-1.849 3.832-.726 2.915 1.451z"/>

  </svg>
)

export default SvgHold;
