import React from "react";
import TableSVG from "../../_ARCHIVE_/assets/svgs/meta/equipment/TableSVG";

const SupportEnum = {
  towel : {
    label : "Towel but table",
    svg : <TableSVG/>
  },
  table : {
    label : "Table",
    svg : <TableSVG/>
  }
};

export default SupportEnum;
