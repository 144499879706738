import React, {Component} from "react"

import style from "./SvgDisplay.module.scss";
import SvgDisplayHelper from "./SvgDisplayHelper";
import ActivityModel from "../../../model/ActivityModel";
import PlaySVG from "../../../../../bosom/_ARCHIVE_/assets/svgs/player/PlaySVG";

class SvgDisplay extends Component {
    constructor(props) {
        super(props);
        ActivityModel.instance.configure(this, ["isPlaying", "stepIndex", "exerciseSvgData"]);
        this.onSvgClick = this.onSvgClick.bind(this);
    }

    onSvgClick(){
        ActivityModel.instance.flipValue("isPlaying");
    }

    render() {
        const showBtn = (!this.state.isPlaying && this.state.exerciseSvgData);
        const btn = showBtn ? <PlaySVG className={style.svg_play_btn}/> : null;
        const svg = SvgDisplayHelper.getSvgElement();

        return (
            <div className={style.parent + " " + this.props.className} onClick={this.onSvgClick}>
                {svg}
                {btn}
            </div>
        )
    }
}

export default SvgDisplay;
