import React, {Component} from "react"
import style from "./Player.module.scss";
import Box from "../../../../bosom/components/layout/boxes/Box";
import SvgDisplay from "./svg-display/SvgDisplay";
import VBox from "../../../../bosom/components/layout/boxes/VBox";
import PlayerControls from "./controls/PlayerControls";
import ActivityModel from "../../model/ActivityModel";
import PlayerTextOverlay from "./text-overlay/PlayerTextOverlay";
import SvgHold from "../../../../assets/svg/core/SvgHold";

class Player extends Component {
    constructor(props) {
        super(props);
        ActivityModel.instance.configure(this, ["isMaximized", "holding"]);
    }

    render() {
        const cls = (this.state.isMaximized) ? " " + style.maxed : "";
        return (
            <VBox className={style.parent + cls}>
                <Box className={style.svg_display_wrapper}>
                    <SvgDisplay/>
                    <PlayerTextOverlay/>
                    <PlayerControls/>
                </Box>
            </VBox>
        )
    }
}

export default Player;
