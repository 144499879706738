import React, {Component} from "react";
import MiscUtil from "../../../bosom/utils/MiscUtil";
import FetchUtil from "../../../bosom/utils/FetchUtil";
import ExerciseVO from "../../../bosom/data/vos/ExerciseVO";
import ActivityModel from "../model/ActivityModel";

let pastKeys = {};
class ActivityController extends Component {
    constructor(props){
        super(props);
        this.model = ActivityModel.instance;
        this.model.entityVO = this.entityVO = props.entityVO;
        this.exerciseMap = props.exerciseMap;
        this.nextKey = this.entityVO.nextKey || this.getRandomNextKey();
        this.onNextJsonLoaded = this.onNextJsonLoaded.bind(this);
        this.onExerciseSvgLoaded = this.onExerciseSvgLoaded.bind(this);
    }

    componentDidMount() {
        this.fetchStuff();
    }

    fetchStuff(){
        FetchUtil.fetchJson("/" + this.exerciseMap[this.nextKey] + "/exercise.json", this.onNextJsonLoaded);
        FetchUtil.fetchSVG(this.entityVO.path + "/exercise.svg", this.onExerciseSvgLoaded);
    }

    onNextJsonLoaded(json){
        MiscUtil.callLater(function(){
            this.model.nextVO = new ExerciseVO(json);
        }.bind(this), 100);
    }

    onExerciseSvgLoaded(svgString){
        MiscUtil.callLater(function(){
            this.model.exerciseSvgData = svgString;
        }.bind(this), 0);

    }

    getRandomNextKey(){
        pastKeys[this.entityVO.key] = this.entityVO.key;
        for(const key in this.exerciseMap){
            if(!pastKeys[key] && this.exerciseMap.hasOwnProperty(key)){
                return key
            }
        }
        pastKeys = {}; // all keys used, reset!!!
        return MiscUtil.getRandomItemFromObject(this.exerciseMap, this.props.entityVO.key).key; // Last Key
    }

    render() {return (<></>)}
}

export default ActivityController;
