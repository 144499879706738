import React, {Component} from "react"
import VBox from "../../../bosom/components/layout/boxes/VBox";

import style from "./Activity.module.scss";
import HBox from "../../../bosom/components/layout/boxes/HBox";
import Player from "./player/Player";
import ActivityModel from "../model/ActivityModel";
import ActivityFooter from "./footer/ActivityFooter";
import UpNext from "./up-next/UpNext";
import ActivityMeta from "./meta/ActivityMeta";

class Activity extends Component {
    constructor(props) {
        super(props);
        ActivityModel.instance.configure(this, ["isPlaying", "exerciseSvgData", "isMaximized"]);
    }

    getChildren(){
        if(this.state.isMaximized){
            return <Player/>
        }
        return <HBox className={style.parent}>
            <VBox className={style.left_wrapper}>
                <Player/>
                <ActivityFooter/>

            </VBox>
            <VBox className={style.right_wrapper}>
                <UpNext className={style.up_next}/>
                <ActivityMeta/>
            </VBox>
        </HBox>;
    }

    render() {
        return (
            this.getChildren()
        )
    }
}

export default Activity;
