import React, {Component} from "react";
import DefaultLayout from "../../bosom/components/layout/default/DefaultLayout";
import CoreEnums from "../../bosom/data/enums/CoreEnums"
import ExerciseVO from "../../bosom/data/vos/ExerciseVO";
import Activity from "../../views/activity/view/Activity";
import ActivityController from "../../views/activity/controllers/ActivityController";
import ProgressController from "../../views/activity/controllers/ProgressController";
import PlayerTextController from "../../views/activity/controllers/PlayerTextController";
import ActivityModel from "../../views/activity/model/ActivityModel";
import UnderConstructions from "../../bosom/components/layout/under-constructions/UnderConstructions";

class ActivityTemplate extends Component {
    constructor(props) {
        super(props);
        ActivityModel.instance.reset();
        this.state = {
            pageContext: this.props.pageContext,
            exerciseMap : this.props.pageContext.exercises,
            entityVO: new ExerciseVO(this.props.pageContext.entity)
        };
    };

    render() {
        const pageTitle = this.state.entityVO.meta_data.title.text;
        return (
            <DefaultLayout title={pageTitle}
                           context={this.state.pageContext}
                           entityKey={this.state.entityVO.key}
                           type={CoreEnums.EXERCISE}>

                <ActivityController entityVO={this.state.entityVO} exerciseMap={this.state.exerciseMap}/>
                <ProgressController entityVO={this.state.entityVO}/>
                <PlayerTextController/>
                <Activity/>
               {/* <UnderConstructions/>*/}
            </DefaultLayout>
        )
    }
}

export default ActivityTemplate;
