import React, {Component} from "react"
import style from "./UpNext.module.scss";
import HBox from "../../../../bosom/components/layout/boxes/HBox";
import ActivityModel from "../../model/ActivityModel";
import {Link} from "gatsby"
import SvgPreviewLoader from "../svg-preview-loader/SvgPreviewLoader";
import VBox from "../../../../bosom/components/layout/boxes/VBox";
import DefaultToggle from "../../../../bosom/components/elements/toggle/default/DefaultToggle";
import MiscUtil from "../../../../bosom/utils/MiscUtil";
import SvgPlay from "../../../../assets/svg/core/SvgPlay";

class UpNext extends Component {
    constructor(props) {
        super(props);
        this.model = ActivityModel.instance;
        this.model.configure(this, ["nextVO", "autoplay"]);
        this.onAutoplayToggle = this.onAutoplayToggle.bind(this);
    }

    onAutoplayToggle() {
        this.model.flipValue("autoplay");
    }

    getMetaItems(){
        const list = [];
        const nextVO = this.state.nextVO;
        if(nextVO){
            list.push(this.getSVGItem(nextVO.category));
            list.push(this.getSVGItem(nextVO.intensity));
            list.push(this.getSVGItem(nextVO.equipment));
            list.push(this.getSVGItem(nextVO.target_area));
        }
        return list;
    }

    getSVGItem(obj){
        if(!obj || !obj.tag_name)
            return null;
        const randKey = MiscUtil.getRandomNumber(0,9999999);
        const tagName =  obj.tag_name.toLowerCase();
        const toPath = "/tags/" + tagName;
        return <Link to={toPath} key={randKey} className={style.tag_item}>
            {tagName}
        </Link>;
    }

    render() {
        const nextVO = this.state.nextVO;
        const svgPath = (nextVO) ? nextVO.path + "/preview.svg" : "";
        const title = (nextVO) ? nextVO.meta_data.title.text : "";
        const summary = (nextVO) ? nextVO.meta_data.summary.text : "";
        const nextUrl = (nextVO) ? nextVO.path : "/";
        const metaItems = this.getMetaItems();
        const cls = style.parent + " " + this.props.className;
        const headingTxt = this.props.headingText || "Up next:";

        return (
            <VBox className={cls}>
                <HBox className={style.header_wrapper}>
                    <h4 className={style.up_next_title}>{headingTxt}</h4>
                    <DefaultToggle label={"autoplay"}
                                   onToggle={this.onAutoplayToggle}
                                   checked={this.state.autoplay}/>
                </HBox>
                <Link to={nextUrl} className={style.next_wrapper}>
                    <div className={style.svg_wrapper}>
                        <SvgPreviewLoader svgPath={svgPath} className={style.svg_preview_comp}/>
                        <SvgPlay className={style.play_svg}/>
                    </div>
                    <h4 className={style.next_title}>{title}</h4>
                    <p className={style.next_summary}>{summary} {summary}</p>
                </Link>
                <HBox className={style.footer_wrapper}>
                    {metaItems}
                </HBox>
            </VBox>

        )
    }
}

export default UpNext;
