import VOBase from "../../base/VOBase"
import CoreEnums from "../enums/CoreEnums";

const setStepsTimeout = function(eVO){
  const stepsCount = eVO.number_of_steps;
  const inclineTimeouts = [];
  const releaseTimeouts = [];
  let totalTime = 0;
  for(let i = 0; i < stepsCount; i++){
    let minVal = eVO.min_step_duration_obj[i];
    minVal = (isNaN(minVal)) ? 0 : minVal;
    const incVal = Math.max(eVO.intensity.time, minVal);
    const relVal = Math.max(eVO.release_intensity.time, minVal);
    inclineTimeouts[i] = incVal;
    releaseTimeouts[i] = relVal;
    totalTime += incVal + relVal;
  }
  eVO.inclineTimeouts = inclineTimeouts;
  eVO.releaseTimeouts = releaseTimeouts;
  eVO.repetitionExecutionTime = totalTime + eVO.wait_time_at_bottom + eVO.wait_time_at_top;
  eVO.totalExecutionTime = eVO.repetitionExecutionTime * eVO.number_of_repetitions - eVO.wait_time_at_bottom;
  eVO.totalNumberOfSteps = (stepsCount - 1) * eVO.number_of_repetitions * 2;
}

class ExerciseVO extends VOBase {

  constructor (jsonObj) {
    super(jsonObj);
    if(!this.key){
      throw new Error("ExerciseVO: Key Missing");
    }
    setStepsTimeout(this);
    //this.previewPath = '/' + this["parent_url"] + "/" + this["url_prefix"] + "/" + this["key"] + "/" + "preview.svg";
    //this.svgPath = '/' + this["parent_url"] + "/" + this["url_prefix"] + "/" + this["key"] + "/" + "preview.svg";
    this.path = '/' + this["parent_url"] + "/" + this["url_prefix"] + "/" + this["key"];
  }
}

export default ExerciseVO;
