import React from "react"
import style from "../../svg.module.scss"

const width = 25;
const height = 25;

const TableSVG = (props) => (
  <svg viewBox="0 -68 480.00001 480"
       height={height} width={width}
       className={style.svg_parent + " " + props.className}
       xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink"
       fillRule="evenodd" clipRule="evenodd">

        <path d="m478.511719 59.351562-40-56c-1.5-2.101562-3.925781-3.351562-6.511719-3.351562h-376c-2.335938.00390625-4.554688 1.023438-6.070312 2.800781l-48 56c-1.246094 1.445313-1.93359425 3.289063-1.929688 5.199219v32c0 4.417969 3.582031 8 8 8h8v232c0 4.417969 3.582031 8 8 8h32c4.417969 0 8-3.582031 8-8v-232h8v176c0 4.417969 3.582031 8 8 8h24c4.417969 0 8-3.582031 8-8v-176h256v176c0 4.417969 3.582031 8 8 8h24c4.417969 0 8-3.582031 8-8v-176h8v232c0 4.417969 3.582031 8 8 8h32c4.417969 0 8-3.582031 8-8v-232h8c4.417969 0 8-3.582031 8-8v-32c0-1.667969-.519531-3.292969-1.488281-4.648438zm-418.832031-43.351562h368.199218l28.578125 40h-431.066406zm-11.679688 312h-16v-224h16zm48-56h-8v-168h8zm296 0h-8v-168h8zm56 56h-16v-224h16zm-432-240v-16h448v16zm0 0"/>

  </svg>
)

export default TableSVG;
